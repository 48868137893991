* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'Montserrat';
}

html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}